<template>
  <!-- https://github.com/DanSnow/vue-recaptcha -->
  <div
    :class="['recaptcha', {'recaptcha--is-invalid': isReCaptchaInvalid}]"
    :id="`recaptcha-${id}`">
    <vue-recaptcha
      ref="vueReCaptcha"
      :sitekey="getReCaptchaSiteKey"
      :loadRecaptchaScript="loadRecaptchaScript"
      :language="getLanguage"
      @render="onRenderCaptcha"
      @verify="onVerifyCaptcha"
      @expired="onExpiredCaptcha"
      @error="onErrorCaptcha">
        <slot name="button"></slot>
    </vue-recaptcha>
    <p v-if="reCaptchaError"
      class="recaptcha__error is-invalid">
      {{ reCaptchaError }}
    </p>
    <!-- <p v-if="isReCaptchaExpired"
      class="recaptcha__error--exipered">
      {{ $t('Verification expired. Check the checkbox again.') }}
    </p> -->
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
// import Config from '@/config'
// import Bus from '@/services/Bus'
// import { toast } from '@/modules/notifications'
export default {
  name: 'reCAPTCHA',
  props: {
    loadRecaptchaScript: {
      type: Boolean,
      required: false,
      default: true
    },
    isReCaptchaVerified: {
      type: Boolean,
      required: false,
      default: false
    },
    reCaptchaToken: {
      type: String,
      required: false,
      default: undefined
    },
    isReCaptchaInvalid: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      id: '',
      reCaptchaError: '',
      isReCaptchaExpired: false,
      tokenReCaptcha: undefined
    }
  },
  computed: {
    getReCaptchaSiteKey () {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY_V2
    },
    getLanguage () {
      return this.$store.getters['ui/language']
    },
    captchaStatus: {
      get () {
        return this.isReCaptchaVerified
      },
      set (newValue) {
        this.$emit('update:isReCaptchaVerified', newValue)
      }
    },
    token: {
      get () {
        return this.reCaptchaToken
      },
      set (newValue) {
        this.$emit('update:reCaptchaToken', newValue)
      }
    },
    captchaValidation: {
      get () {
        return this.isReCaptchaInvalid
      },
      set (newValue) {
        this.$emit('update:isReCaptchaInvalid', newValue)
      }
    }
  },
  methods: {
    onRenderCaptcha (id) {
      this.id = id
    },
    /* Verifying the user's response - https://developers.google.com/recaptcha/docs/verify */
    onVerifyCaptcha (token) {
      this.token = token
      return this.$emit('captcha-validated', token)
      // let url = `${Config.reCAPTCHA.verifyTokenEndpoint}?response=${token}`
      // Bus.get(url)
      //   .then(res => {
      //     if (res.status === 200 && res.data.success) {
      //       _this.captchaStatus = true
      //       _this.captchaValidation = false
      //       _this.isReCaptchaExpired = false
      //       _this.reCaptchaError = ''
      //     } else {
      //       toast.error(`reCAPTCHA - ${res.data['error-codes'][0]}`)
      //       // reset of captcha broke language prop. See options docs at https://github.com/DanSnow/vue-recaptcha#api
      //       _this.$refs.vueReCaptcha.reset()
      //     }
      //   })
      //   .catch(error => {
      //     _this.$refs.vueReCaptcha.reset()
      //     toast.error(error)
      //   })
    },
    onExpiredCaptcha () {
      this.isReCaptchaExpired = true
      this.captchaStatus = false
    },
    onErrorCaptcha (error) {
      this.reCaptchaError = error
    }
  },
  components: {
    VueRecaptcha
  }
}
</script>

<style>
  .recaptcha {
    width: fit-content;
  }
  .recaptcha.recaptcha--is-invalid iframe {
    border: 1px solid #ff3860;
  }
</style>
