var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-page-container"},[_c('modalTerms',{attrs:{"pageID":_vm.modalTerms.pageID},on:{"close":function($event){_vm.openModalTerms ({open: false, pageID: ''})}},model:{value:(_vm.modalTerms.open),callback:function ($$v) {_vm.$set(_vm.modalTerms, "open", $$v)},expression:"modalTerms.open"}}),_c('div',{staticClass:"aqz-container"},[_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"md":"10"}},[_c('h3',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t('create-your-account')))]),_c('form',{staticClass:"register-form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-row',{staticClass:"align-items-end"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"groupTitle","label-for":"title"}},[_c('base-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"title","required":"","options":_vm.prefixOptions,"validations":[
                    {
                      condition: _vm.errors.has('title'),
                      text: _vm.errors.first('title')
                    }
                  ],"placeholder":'*' + _vm.$t('address.Title')},model:{value:(_vm.form.prefix),callback:function ($$v) {_vm.$set(_vm.form, "prefix", $$v)},expression:"form.prefix"}})],1)],1),_c('b-col',{staticClass:"align-self-start text-right",attrs:{"cols":"6"}},[_c('div',{staticClass:"mandatory-field"},[_c('span',{staticClass:"f-very-small cl-black"},[_vm._v("*"+_vm._s(_vm.$t('mandatory-field')))])])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"groupFirstname","label-for":"firstname"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"firstname","type":"text","placeholder":'*' + _vm.$t('address.FirstName'),"validations":[
                    {
                      condition: _vm.errors.has('firstname'),
                      text: _vm.errors.first('firstname')
                    }
                  ]},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"groupLastname","label-for":"lastname"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"lastname","type":"text","placeholder":'*' + _vm.$t('address.LastName'),"validations":[
                    {
                      condition: _vm.errors.has('lastname'),
                      text: _vm.errors.first('lastname')
                    }
                  ]},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"groupEmail","label-for":"email"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"name":"email","type":"text","autocomplete":"username","placeholder":'*' + _vm.$t('address.Email'),"validations":[
                    {
                      condition: _vm.errors.has('email'),
                      text: _vm.errors.first('email')
                    }
                  ]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"groupPassword","label-for":"password"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:8'),expression:"'required|min:8'"}],ref:"pw_confirm",attrs:{"name":"password","data-vv-as":_vm.$t('Password'),"type":"password","autocomplete":"new-password","placeholder":'*' + _vm.$t('Password'),"validations":[
                    {
                      condition: _vm.errors.has('password'),
                      text: _vm.errors.first('password')
                    }
                  ]},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"groupPasswordConfirm","label-for":"passwordConfirm"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:pw_confirm'),expression:"'required|confirmed:pw_confirm'"}],attrs:{"name":"passwordConfirm","data-vv-as":_vm.$t('Password'),"type":"password","autocomplete":"new-password","placeholder":'*' + _vm.$t('Confirm Password'),"validations":[
                    {
                      condition: _vm.errors.has('passwordConfirm'),
                      text: _vm.errors.first('passwordConfirm')
                    }
                  ]},model:{value:(_vm.form.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.form, "passwordConfirm", $$v)},expression:"form.passwordConfirm"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"id":"groupDob","label":_vm.$t('dob'),"label-for":"dob"}},[_c('no-ssr',[_c('input-dob',{attrs:{"id":"dob"},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"block-privacy",attrs:{"cols":"12"}},[_c('div',{staticClass:"privacy-intro"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.registrationPrivacyLabel)}})]),_vm._l((_vm.form.privacy),function(value,key){return _c('div',{key:key,staticClass:"privacy-terms"},[_c('b-form-group',{attrs:{"id":'checkbox_'+key,"label-for":key}},[_c('base-check-box',{directives:[{name:"validate",rawName:"v-validate",value:((_vm.privacyMandatory[key] ? 'required:true' : '')),expression:"(privacyMandatory[key] ? 'required:true' : '')"}],key:key,attrs:{"name":key,"label":_vm.labelCheckbox[key],"validations":[
                      {
                        condition: _vm.errors.has(key),
                        text: _vm.errors.first(key)
                      }
                    ]},on:{"label-clicked":function($event){return _vm.openModalTerms({ open: true, pageID: key })}},model:{value:(_vm.form.privacy[key]),callback:function ($$v) {_vm.$set(_vm.form.privacy, key, $$v)},expression:"form.privacy[key]"}})],1)],1)})],2)],1),_c('b-row',[_c('b-col',[_c('re-CAPTCHA',{ref:"nlReCaptcha",attrs:{"isReCaptchaInvalid":_vm.isReCaptchaInvalid,"reCaptchaToken":_vm.reCaptchaToken},on:{"update:isReCaptchaInvalid":function($event){_vm.isReCaptchaInvalid=$event},"update:is-re-captcha-invalid":function($event){_vm.isReCaptchaInvalid=$event},"update:reCaptchaToken":function($event){_vm.reCaptchaToken=$event},"update:re-captcha-token":function($event){_vm.reCaptchaToken=$event}}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.serverValidation),expression:"serverValidation"}],staticClass:"form-errors is-invalid"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.serverValidation)}})]),_c('div',{staticClass:"block-submit text-center d-md-flex align-items-center"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('create-your-account')))])])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }